@import "../../styles/shared/_variables.scss";

.expandPanelCard.card {
  border: 0;
  background: #fff;
  overflow: visible !important;

  .btn-link {
    font-size: 14px;
    @include agbold();
    padding: 5px 10px;
    background: $mist;
    color: $stratus;
    border-radius: 16px;

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &.inlineLink {
      font-size: 13px;
      &:after {
        content: none !important;
      }
    }

    &:hover {
      @include media-breakpoint-up(lg) {
        background: $salesforce-blue-6;
        color: $default-solid;
        svg {
          path {
            fill: $default-solid;
          }
        }
      }
    }

    svg {
      margin-left: 8px;
      transition: 0.2s ease-in-out all;
    }
  }

  .card-header {
    background: #fff;
    padding: 10px 0 10px 0;
    border: 0;
    /*@include media-breakpoint-down(md) {
      background: $karl-the-fog;
    }*/
  }

  .card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  /*@include media-breakpoint-down(md) {
    background: $karl-the-fog;
  }*/
}

.accordion.expanded {
  .expandPanelCard.card {
    .btn-link {
      color: $default-solid;
      background: $salesforce-blue-6;

      svg {
        transform: rotate(180deg);
        path {
          fill: $default-solid;
        }
      }
    }
  }
}
