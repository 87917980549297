@import "../../styles/shared/_variables.scss";

.hubContainer {
  min-height: 100vh;
}

.hubHeader {
  min-height: 200px;
  padding-right: 40px;

  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }

  .hubIcon {
    width: 150px;

    svg {
      width: 150px;
      height: 150px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;

    svg {
      margin: 0 auto;
    }
    @include media-breakpoint-down(md) {
      float: left;
      margin-right: 20px;
    }
  }

  h2 {
    margin: 20px 0;
    font-size: 20px;
    color: $night;
    position: relative;
    @include sansbold();

    @include media-breakpoint-down(md) {
      font-size: 18px;
      margin-top: 10px;
    }
  }

  .hubBreadCrumb {
    text-transform: uppercase;
    @include sansbold();
    font-size: 11px;
    line-height: 15px;
    /* identical to box height, or 136% */

    letter-spacing: 1px;
    text-transform: uppercase;

    color: $cirrus;

    a {
      position: relative;
    }

    svg {
      margin-right: 5px;
      margin-top: -1px;
    }

    svg path {
      fill: $cirrus;
    }

    .hubLink-marketing {
      color: $marketing-solid;
      svg path {
        fill: $marketing-solid;
      }
    }

    .hubLink-sales {
      color: $sales-solid;
      svg path {
        fill: $sales-solid;
      }
    }

    .hubLink-service {
      color: $service-solid;
      svg path {
        fill: $service-solid;
      }
    }

    .hubLink-commerce {
      color: $commerce-solid;
      svg path {
        fill: $commerce-solid;
      }
    }

    .hubLink-platform {
      color: $platform-solid;
      svg path {
        fill: $platform-solid;
      }
    }

    .hubLink-industries {
      color: $industry-solid;
      svg path {
        fill: $industry-solid;
      }
    }
  }
}

.hubFeaturedEvent {
  display: block;
  padding-top: 30px;

  .eventCard.card {
    background-color: $earth-mid;
    background-size: cover;
    height: auto;

    .vidyard-player-container {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .portraitFeatureDetails {
      background: #fff;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 20px;
      background-color: $earth-mid;
      position: relative;

      .eventTitle {
        display: block;
        margin-top: 0;
        color: $stratus;
        @include agbold();

        line-height: 26px;
        font-size: 1.2rem;

        @include media-breakpoint-up(lg) {
          font-size: 28px;
          line-height: 36px;
        }
      }

      .eventSub {
        @include sansbold();
        line-height: 32px;
        display: block;
        color: $stratus;

        &.eyebrow {
          text-transform: uppercase;
        }
      }
    }

    .like-container {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      z-index: 100;
      cursor: pointer;
    }
  }
}

.hubListPromoPanel {
  border-radius: 15px;
  min-height: 268px;
  background: $light-yellow;
  padding: 40px;

  background-image: url("../../assets/images/promo-images/personalized-guidance.png");
  background-image: -webkit-image-set(
    url("../../assets/images/promo-images/personalized-guidance.png") 1x,
    url("../../assets/images/promo-images/personalized-guidance@2x.png") 2x
  );
  background-repeat: no-repeat;
  background-position: right;
  background-size: 40%;

  @include media-breakpoint-down(sm) {
    background-position: center top 10px;
    background-size: 50%;
    padding-top: 50%;
  }

  @include media-breakpoint-down(md) {
    margin-top: 30px;
  }

  .groupHeader {
    font-size: 22px;
    padding: 0px;
  }

  p {
    color: $stratus;
  }
}
